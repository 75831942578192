<div class="sidenav flex h-full w-full flex-col shadow-md">
  <div class="flex justify-center pb-4 pt-6 text-center">
    <img
      ngSrc="../../assets/img/logo-blanco.png"
      width="180"
      height="180"
      alt="Logo de I-Caranty"
      class="cursor-pointer"
      routerLink="/dashboard"
      priority />
  </div>

  <div class="flex items-center justify-center">
    <span class="mb-4 cursor-pointer text-center text-4xl font-extrabold tracking-[0.4em] text-white" routerLink="/">
      CRM
    </span>

    <span class="text-sm text-white"> v{{ versionApp$ | async }} </span>
  </div>

  <div class="mb-4 ml-3 flex flex-col">
    <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.givenName }}</span>
    <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.surname }}</span>
    <span class="font-semibold text-ica-primary">{{ dataProfile?.userPrincipalName }}</span>
  </div>

  <ul class="ml-3 mr-2 flex h-3/4 flex-col">
    <li
      [ngClass]="{ 'my-1 pl-3': menu.type !== 'modulo', 'border-t-2 border-t-zinc-200 py-3': menu.type === 'modulo' }"
      *ngFor="let menu of menu">
      <ng-container *ngIf="menu.inProduction && environment" [ngTemplateOutlet]="item"> </ng-container>

      <ng-container *ngIf="!environment" [ngTemplateOutlet]="item"> </ng-container>

      <ng-template #item>
        <a
          *ngIf="menu.routerLink"
          mat-button
          [routerLink]="menu.routerLink"
          routerLinkActive="active"
          class="flex content-center">
          <!--[routerLinkActiveOptions]="{exact: true}" -> se desactiva la ruta exacta para el activado del menu--->
          <span [ngClass]="menu.type === 'modulo' ? 'li-menu-module' : 'li-menu'">
            {{ menu.label }}
          </span>
        </a>
      </ng-template>
    </li>
  </ul>
</div>
