import { NgModule, ErrorHandler, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http'; // Import
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuicklinkModule } from 'ngx-quicklink';

import { GenerateIcsComponent } from './generate-ics/generate-ics.component';

import { GlobalErrorHandler } from '@core/global-error-handler';
import { CustomHttpInterceptor } from '@core/interceptors/custom.http.interceptor';
import { LoaderComponent } from '@shared/components/loader/loader.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from '@auth/login/login.component';

import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

// MS Sesion
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular'; // Updated import

import {
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  IPublicClientApplication,
} from '@azure/msal-browser';

//Modulos
import { SharedModule } from './shared/shared.module';
import { FailedComponent } from './shared/failed/failed.component';

//Idioma Angular
import { LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
//import { FlexLayoutModule } from '@angular/flex-layout';
registerLocaleData(localeEs, 'es-MX'); //Esto no es un import, pero va justo despues de ellos!

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RingCentralComponent } from './ring-central/ring-central.component';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ROOT_REDUCERS } from './app.state';
import { ContratosEffects } from './contratos/contratos-v2/state';
import { StateGlobalEffects } from '@core/stateGlobal';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ExpedienteEffects } from './expediente/state';
import { initializerApp } from '@core/initializer/initializer-app';
import { UserEffects } from './usuario/state';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalAuth.clientId, // This is your client ID
      authority: environment.msalAuth.authority, // This is your tenant ID
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.msalAuth.graphEndpoint}/me`, environment.msalAuth.scopes);
  protectedResourceMap.set(`${environment.msalAuth.graphEndpoint}/users`, environment.msalAuth.scopes);
  protectedResourceMap.set(`${environment.msalAuth.graphEndpoint}/groups`, environment.msalAuth.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msalAuth.scopes,
    },
    loginFailedRoute: '/login-failed',
  };
}

//Socker IO
const config: SocketIoConfig = environment.notificationWss.socketConfig;

@NgModule({
  declarations: [AppComponent, FailedComponent, LoaderComponent, GenerateIcsComponent],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    initializerApp,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    MsalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    QuicklinkModule,
    RingCentralComponent,
    StoreModule.forRoot(ROOT_REDUCERS),
    EffectsModule.forRoot([ContratosEffects, StateGlobalEffects, ExpedienteEffects, UserEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    LoginComponent,

    SocketIoModule.forRoot(config),
  ],
})
export class AppModule {}
