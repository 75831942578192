import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'ic-refresh-alert',
  templateUrl: './refresh-alert.component.html',
  styleUrls: ['./refresh-alert.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class RefreshAlertComponent implements OnInit, OnDestroy {
  readonly _utilsService$ = inject(LoaderAndUtilsService);
  public isNewVersion$ = new BehaviorSubject(true);
  private _alertService = inject(AlertService);
  private subs = new SubSink();

  ngOnInit(): void {
    this.subs.add(
      this._utilsService$.isNewVersion$.subscribe((data) => {
        this.isNewVersion$.next(data);
        if (data) {
          this._alertService
            .confirmAction(
              'Se ha actualizado la versión de la aplicación, ¿Desea recargar la página?',
              'Actualizar',
              'info',
              true,
              'Recargar',
              'Mas tarde'
            )
            .then((result) => {
              if (result.isConfirmed) {
                this.reloadPage();
              }
            });
        }
      })
    );
  }

  reloadPage() {
    this._utilsService$.isNewVersion$.next(false);
    location.reload();
    window.history.forward();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
